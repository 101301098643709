export const defaultFeatureFlags = {
  maintenance: false, // Maintenance mode (DO NOT REMOVE)
  troubleshooterWeights: false, // Use graph weights as a limit for selection (MXPRODS-3491)
  arrhythmiaTool: false, // Arrhythmia tool (MXPRODS-4055)
  warrantyDatesOnDashboard: false, // Show warranty dates on dashboard (MXPRODS-3909)
  remoteAppActions: false, // Remote app actions triggered from Darwin, DONT REMOVE (MXPRODS-3762)
  qaDiagnosticFieldSync: false, //QA diagnostics field sync (MXPRODS-5151)
  returnEligibilityFeature: false, //Show if ring is eligible for return (MXPRODS-5322)
  prepaidMove: false,
  prepaidApply: false,
  prepaidRemove: false,
  prepaidDiagnostics: false, // Show prepaid diagnostics on Subscription (MXPRODS-5838)
  hiddenDiagnosticsFeature: false, // Don't remove
  hiddenDiagnostics: '', // Don't remove
  showEssenceId: false, // MXPRODS-5791 Show essence id in user details
  chatbotKeyGeneration: true, // MXPRODS-5860 - Chatbot key generation
  persistTroubleshooterSelectionsWithSessionStorage: false, // Persist troubleshooter selections with session storage (MXPRODS-5593)
}
