<template>
  <v-card :loading="applyingPrepaid">
    <v-card-title>Apply prepaid period</v-card-title>
    <v-card-subtitle>Add a new prepaid period to this account</v-card-subtitle>
    <v-card-text>
      <v-progress-circular
        v-show="applyingPrepaid"
        indeterminate
        size="70"
        width="8"
        style="position: absolute; top: 50%; left: 45%; z-index: 100"
      />

      <v-row ref="notification">
        <div v-if="notificationMessage" style="position: sticky; top: 60px; width: 100%">
          <v-sheet min-height="50">
            <v-alert v-if="notificationMessage" :type="notificationType">
              <div>
                {{ notificationMessage }}
              </div>
            </v-alert>
          </v-sheet>
        </div>
      </v-row>
      <v-checkbox
        v-model="applyToCurrentBillingPeriod"
        :disabled="disableOnSuccess"
        label="Apply to ongoing billing period"
        class="ml-n2"
      />

      <v-text-field
        v-model="lengthInMonths"
        :disabled="disableOnSuccess"
        label="Total lengths in months"
        type="number"
        hint="Prepaid length regardless when it starts"
        persistent-hint
        class="mt-4 ml-1"
        :rules="[
          (v) => !!v || 'Prepaid length is required and must be a valid number.',
          (v) => v <= 12 || 'Prepaid length cannot exceed 12 months.',
        ]"
        required
        :max="12"
      />

      <v-textarea
        v-model="reason"
        :disabled="disableOnSuccess"
        label="Reason"
        rows="2"
        class="mt-6 ml-1"
        :rules="[(v) => !!v || 'Reason is required']"
        required
      />
    </v-card-text>

    <v-card-actions>
      <v-btn color="primary" :disabled="applyingPrepaid" variant="text" @click="back()">Back</v-btn>
      <v-spacer />
      <v-btn color="primary" :disabled="applyingPrepaid" variant="text" @click="close()">Close</v-btn>

      <v-btn color="primary" :disabled="confirmDisabled" variant="text" @click="applyPrepaid()">Confirm</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { PrepaidStore, SubscriptionStore } from '#stores'

  import { Member } from '#types'

  @Component
  export class PrepaidApply extends Vue {
    @Prop() public member!: Member

    public prepaidStore = new PrepaidStore()
    public subscriptionStore = new SubscriptionStore()

    public reason: string = ''
    public applyToCurrentBillingPeriod: boolean = true

    /**
     * Total length of prepaid in months regardless when it starts
     * default = 12
     */
    public lengthInMonths: number = 12
    public notificationMessage: string = ''
    public notificationType: 'error' | 'success' = 'success'
    public justSaved: boolean = false

    public get applyingPrepaid() {
      return this.prepaidStore.applyingPrepaidPlan
    }

    public get disableOnSuccess() {
      return this.applyingPrepaid || this.justSaved
    }

    public get confirmDisabled(): boolean {
      return !this.reason || !this.lengthInMonths || this.disableOnSuccess
    }

    public async applyPrepaid() {
      await this.prepaidStore.applyPrepaid({
        memberId: this.member.uuid,
        applyToCurrentBillingPeriod: this.applyToCurrentBillingPeriod,
        lengthInMonths: this.lengthInMonths,
        reason: this.reason,
      })

      const requestError = this.prepaidStore.prepaidApplyError
      if (requestError) {
        this.notificationMessage = requestError.userMessage ?? 'Something went wrong'
        this.notificationType = 'error'
      } else {
        this.notificationMessage = 'Prepaid plan applied successfully'
        this.notificationType = 'success'
        this.justSaved = true
        await this.subscriptionStore.getSubscriptions({ initial: false, userId: this.member.uuid })
      }
    }

    public created() {
      this.reset()
    }

    private reset() {
      this.reason = ''
      this.applyToCurrentBillingPeriod = true
      this.lengthInMonths = 12
      this.notificationMessage = ''
      this.justSaved = false
      this.prepaidStore.$reset()
    }

    public close() {
      this.reset()
      this.$emit('close')
    }

    public back() {
      this.reset()
      this.$emit('back')
    }
  }

  export default toNative(PrepaidApply)
</script>
