// sort-imports-ignore

import App from '#App.vue'

import router from '#router'

import { createPinia } from 'pinia'
import { createVuetify } from 'vuetify'
import { createApp, markRaw } from 'vue'

import { initializeApp } from 'firebase/app'

import { getStorage } from 'firebase/storage'
import { getAnalytics, initializeAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'

import { getRemoteConfig } from 'firebase/remote-config'

import { VDateInput } from 'vuetify/labs/VDateInput'

import { DateRangePicker } from '@jouzen/outo-toolkit-vuetify'

import ZAFClient from 'zendesk_app_framework_sdk'

import 'vuetify/styles'

import '@mdi/font/css/materialdesignicons.css'
import '@jouzen/outo-toolkit-vuetify/style.css'
import 'diff2html/bundles/css/diff2html.min.css'

import '@jouzen/outo-toolkit-vuetify/vuetify.scss'
import '@jouzen/outo-toolkit-vuetify/markdown.scss'

import {
  defaults,
  storeReset,
  DateAdapter,
  activateRemoteConfig,
  createVueDayjsPlugin,
  createVueSentryPlugin,
  createVueNotifyPlugin,
  createVueConfirmPlugin,
  createVueFirebasePlugin,
  createVueAtlassianPlugin,
  createPiniaAxiosPlugin,
  createPiniaFirebasePlugin,
} from '@jouzen/outo-toolkit-vuetify'
import { getAuth } from 'firebase/auth'
import { defaultFeatureFlags } from '#featureFlags'

const app = createApp(App)
const pinia = createPinia()

const vuetify = createVuetify({
  icons: {
    defaultSet: 'mdi',
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      dark: {
        dark: true,
        colors: {
          info: '#42A5F5', // blue-lighten-1 (material palette)
          error: '#EF5350', // red-lighten-1 (material palette)
          success: '#66BB6A', // green-lighten-1 (material palette)
          warning: '#FF9800', // orange-lighten-1 (material palette)
          surface: '#373737', // lighten(#181818, 12%) (custom color)
          primary: '#EC407A', // <color>-lighten-1 (pink | purple | indigo)
          secondary: '#310614', // color-mix(#000000, 100%, <primary>, 50%)
          background: '#211B1C', // color-mix(#181818, 100%, <primary>, 5%)
        },
        variables: {
          'high-emphasis-opacity': 1.0,
        },
      },
      light: {
        dark: false,
        colors: {
          info: '#42A5F5', // blue-lighten-1 (material palette)
          error: '#EF5350', // red-lighten-1 (material palette)
          success: '#66BB6A', // green-lighten-1 (material palette)
          warning: '#FF9800', // orange-lighten-1 (material palette)
          surface: '#FFFFFF', // lighten(#F8F8F8, 2.5%) (custom color)
          primary: '#EC407A', // <color>-lighten-1 (pink | purple | indigo)
          secondary: '#FFDCE3', // color-mix(#ffffff, <primary-color-hex>, 25%)
          background: '#F9F4F5', // color-mix(#f8f8f8, <primary-color-hex>, 2.5%)
        },
        variables: {
          'high-emphasis-opacity': 1.0,
        },
      },
    },
  },
  date: {
    adapter: DateAdapter,
  },
  defaults,
  components: {
    VDateInput,
    DateRangePicker,
  },
})

const zendesk = ZAFClient.init()

const firebase = initializeApp({
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
})

let analytics = null
if (window.location.pathname == '/sidecar') {
  // Since sidecar is embedded in an iframe the cookie flags need to be set to allow cross-site cookies
  // otherwise the analytics will not work and the remote config will always fetch the default values
  try {
    analytics = initializeAnalytics(firebase, {
      config: {
        cookie_flags: 'SameSite=None; Secure',
      },
    })
  } catch (_error) {
    analytics = getAnalytics(firebase)
  }
} else {
  analytics = getAnalytics(firebase)
}

app.use(pinia)
app.use(router)
app.use(vuetify)

app.use(createVueDayjsPlugin())
app.use(
  createVueSentryPlugin({
    router,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: process.env.APP_RELEASE_VERSION,
    environment: import.meta.env.VITE_APP_ENV,
    tracingOrigin: import.meta.env.VITE_API_URL,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.05,
  }),
)
app.use(
  createVueFirebasePlugin({
    firebase: firebase,
    analytics: analytics,
    remoteConfig: getRemoteConfig(firebase),
  }),
)
app.use(
  createVueAtlassianPlugin({
    component: import.meta.env.VITE_JIRA_COMPONENT_ID,
    environment: import.meta.env.VITE_APP_ENV,
  }),
)
app.use(createVueNotifyPlugin(), vuetify)
app.use(createVueConfirmPlugin(), vuetify)

pinia.use(storeReset)

pinia.use(({ store }) => {
  store.$router = markRaw(router)
})

pinia.use(
  createPiniaAxiosPlugin({
    throwErrors: true,
    auth: getAuth(firebase),
    url: import.meta.env.VITE_API_URL,
    envs: {
      test: import.meta.env.VITE_API_URL_TEST,
      stage: import.meta.env.VITE_API_URL_STAGE,
    },
    version: process.env.APP_RELEASE_VERSION,
  }),
)
pinia.use(
  createPiniaFirebasePlugin({
    firebase: firebase,

    fbstorage: getStorage(firebase),
    firestore: getFirestore(firebase),

    remoteConfig: getRemoteConfig(firebase),

    functions: getFunctions(firebase, 'europe-west1'),
  }),
)

app.config.globalProperties.$zafClient = zendesk

app.config.globalProperties.productionTip = false

activateRemoteConfig({
  remoteConfig: getRemoteConfig(firebase),

  defaultConfig: {
    ...defaultFeatureFlags,
  },
})
  .then(() => {
    console.log('Firebase initialized')
  })
  .catch(() => {
    console.log('Failed to initialize firebase')
  })
  .finally(() => {
    app.mount('#app')
  })
