<template>
  <div>
    <v-card :loading="dataWait">
      <v-card-title :style="{ cursor: sidecar ? 'pointer' : 'default' }">
        <v-col>
          <v-row v-if="!sidecar" justify="space-between" @click="link ? moreInfo() : null">
            <p class="text-overline">Diagnostics</p>
            <template v-if="$featureEnabled('hiddenDiagnosticsFeature')">
              <v-btn
                icon
                dark
                variant="plain"
                :color="showHiddenDiagnostics ? 'green' : 'red'"
                @click="toggleHiddenDiagnostics()"
              >
                <v-tooltip top>
                  <template #activator="{ props }">
                    <v-icon v-bind="props">
                      {{ showHiddenDiagnostics ? 'mdi-ghost-outline' : 'mdi-ghost-off-outline' }}
                    </v-icon>
                  </template>
                  <span>{{ showHiddenDiagnostics ? 'Hide' : 'Show' }} hidden diagnostics (In testing)</span>
                </v-tooltip>
              </v-btn>
            </template>
          </v-row>

          <v-row>
            <v-text-field
              v-if="sidecar"
              label="Ring Serial"
              :disabled="!ring?.serialNumber"
              :model-value="ring?.serialNumber || 'Ring not registered'"
              readonly
            >
              <template #prepend>
                <v-tooltip location="top">
                  <template #activator="{ props }">
                    <v-btn icon :disabled="!ring?.serialNumber" v-bind="props" @click.stop="copyToClipboard()">
                      <v-icon color="grey" icon="mdi-content-copy" />
                    </v-btn>
                  </template>
                  {{ useClipboard.copied ? 'Copied' : 'Copy to clipboard' }}
                </v-tooltip>
              </template>

              <template v-if="$featureEnabled('hiddenDiagnosticsFeature')" #append>
                <v-btn
                  icon
                  dark
                  variant="plain"
                  :color="showHiddenDiagnostics ? 'green' : 'red'"
                  @click="toggleHiddenDiagnostics()"
                >
                  <v-tooltip top>
                    <template #activator="{ props }">
                      <v-icon v-bind="props">
                        {{ showHiddenDiagnostics ? 'mdi-ghost-outline' : 'mdi-ghost-off-outline' }}
                      </v-icon>
                    </template>
                    <span>{{ showHiddenDiagnostics ? 'Hide' : 'Show' }} hidden diagnostics (In testing)</span>
                  </v-tooltip>
                </v-btn>
              </template>
            </v-text-field>

            <v-tooltip v-if="link && sidecar" location="bottom">
              <template #activator="{ props }">
                <v-btn
                  v-if="link"
                  icon="mdi-share"
                  variant="plain"
                  color="blue"
                  class="mt-n1 ml-2 mr-n4"
                  v-bind="props"
                  @click="moreInfo()"
                />
              </template>

              <span>Go to Darwin</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-card-title>

      <div v-if="user && groupedDiagnostics">
        <DiagnosticGroup
          v-for="(diagnosticsGroup, index) of groupedDiagnostics"
          :key="index"
          :diagnostics-group="diagnosticsGroup"
          :sidecar="sidecar"
          :show-hidden-diagnostics="showHiddenDiagnostics"
        />
      </div>
    </v-card>

    <v-snackbar v-model="maxPinsReached">Maximum number of pinned diagnostics reached</v-snackbar>
  </div>
</template>

<script lang="ts">
  import { isEqual } from 'lodash-es'

  import { useClipboard } from '@vueuse/core'

  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { logEvent } from 'firebase/analytics'

  import * as Sentry from '@sentry/browser'

  import { Debounce } from '@jouzen/outo-toolkit-vuetify'

  import { extractGroupedDiagnostics } from '#utils/diagnostics/extract.groupedDiagnostics'

  import { DiagnosticsStoreV3, SidecarStore } from '#stores'

  import { AvailableDiagnostic, DiagnosticGroupData, DiagnosticsContainer, Member, Ring } from '#types'

  export enum DiagnosticsSubComponents {
    Subscription = 'subscription',
    Age = 'age',
    Samples = 'samples',
    Warranty = 'warranty',
    Replaced = 'replaced',
    BatteryHealthy = 'battery_healthy',
    SelfTestHealth = 'self_test_health',
    Chronotype = 'chronotype',
    RingPreOwned = 'pre_owned',
    Retail = 'retail',
    IsSupportedDevice = 'is_supported_device',
    CycleInsights = 'cycle_insights',
    Stress = 'stress',
    StressError = 'stress_error',
    SignupFlow = 'signup_flow',
    MultipleRingsInUse = 'multiple_rings_in_use',
    AutomaticActivity = 'automatic_activity',
    Resilience = 'resilience',
    PartnershipIntegration = 'partnership_integration',
    Connectivity = 'connectivity',
    RingReplacements = 'ring_replacements',
    OldRingInUse = 'old_ring_in_use',
    PreviouslyOwnedRing = 'previously_owned_ring',
    CardioVascularAge = 'cardio_vascular_age',
    Vo2Max = 'vo2_max',
    Device = 'device',
    Version = 'version',
    BoughtFromPartner = 'bought_from_partner',
  }

  @Component
  class DiagnosticsResult extends Vue {
    @Prop() public user!: Member
    @Prop() public ring!: Ring
    @Prop() public sidecar!: boolean
    @Prop() public link!: string
    @Prop() public visibleDiagnostics!: string[]

    public showHiddenDiagnostics = false

    public useClipboard = useClipboard()

    public diagnosticsStore = new DiagnosticsStoreV3()
    public SidecarStore = new SidecarStore()

    public qaDiagnosticsFieldId = import.meta.env.VITE_ZENDESK_QA_DIAGNOSTICS_FIELD_ID
    public qaDiagnosticsField = ''
    public currentAlerts: string = ''

    @Watch('user', { immediate: true })
    protected onUserChanged(_val: any[], _prevVal: any[]) {
      this.diagnosticsStore.getAvailableDiagnostic()
    }

    @Watch('ring')
    protected onRingChanged(_val: any[], _prevVal: any[]) {
      this.runDiagnostics()
    }

    @Watch('availableDiagnostics', { immediate: true })
    protected onAvailableDiagnosticsChange(val: any[], prevVal: any[]) {
      if (!isEqual(val, prevVal)) {
        this.runDiagnostics()
      }
    }

    @Watch('maxPinsReached')
    protected onMaxPinsReached(val: boolean) {
      if (val) {
        setTimeout(() => {
          this.diagnosticsStore.maxPinsReached = false
        }, 3000)
      }
    }
    public copyToClipboard() {
      try {
        window.focus()
        this.useClipboard.copy(this.ring.serialNumber)
      } catch (err) {
        const error = new Error('copyToClipboard error: ' + err)
        Sentry.captureException(error)
      }
    }

    public toggleHiddenDiagnostics() {
      this.showHiddenDiagnostics = !this.showHiddenDiagnostics
      window.sessionStorage.setItem('showHiddenDiagnostics', String(this.showHiddenDiagnostics))
    }

    public get availableDiagnostics(): AvailableDiagnostic[] {
      return this.diagnosticsStore.availableDiagnostics
    }

    public get visibleAndAvailableDiagnostics(): AvailableDiagnostic[] {
      return this.availableDiagnostics.filter(
        (diagnostic) => !this.visibleDiagnostics || this.visibleDiagnostics.includes(diagnostic.diagnosticId),
      )
    }

    public get diagnostics(): DiagnosticsContainer {
      return this.diagnosticsStore.diagnostics
    }

    public get maxPinsReached(): boolean {
      return this.diagnosticsStore.maxPinsReached
    }

    public get dataWait(): boolean {
      return this.diagnosticsStore.waitingForData()
    }

    public get groupedDiagnostics(): DiagnosticGroupData[] {
      const filteredDiagnostics: DiagnosticsContainer = {}
      if (this.$featureEnabled('qaDiagnosticFieldSync') && this.$zafClient && !this.dataWait) {
        this.currentAlerts = this.generateCurrentAlerts()
      }

      for (const availableDiagnostic of this.visibleAndAvailableDiagnostics) {
        if (this.diagnostics[availableDiagnostic.diagnosticId]) {
          filteredDiagnostics[availableDiagnostic.diagnosticId] = this.diagnostics[availableDiagnostic.diagnosticId]
        }
      }
      return extractGroupedDiagnostics(filteredDiagnostics, this.diagnosticsStore.pinnedDiagnostics)
    }

    public async mounted() {
      await this.diagnosticsStore.getAvailableDiagnostic()

      if (this.$featureEnabled('hiddenDiagnosticsFeature')) {
        this.showHiddenDiagnostics = window.sessionStorage.getItem('showHiddenDiagnostics') == 'true'
      } else {
        this.showHiddenDiagnostics = false
        window.sessionStorage.setItem('showHiddenDiagnostics', String(this.showHiddenDiagnostics))
      }
      if (this.$zafClient) {
        await this.SidecarStore.getSidecarDiagnostics(this.$zafClient)
        if (this.$featureEnabled('qaDiagnosticFieldSync')) {
          const data = await this.$zafClient.get([`ticket.customField:custom_field_${this.qaDiagnosticsFieldId}`])
          this.qaDiagnosticsField = data[`ticket.customField:custom_field_${this.qaDiagnosticsFieldId}`]
          this.setupTicketSubmitListener()
        }
      }
    }

    public generateCurrentAlerts(): string {
      return this.visibleAndAvailableDiagnostics
        .filter((diagnostic) => this.diagnostics[diagnostic.diagnosticId])
        .map((diagnostic) => `${diagnostic.diagnosticId} : ${this.diagnostics[diagnostic.diagnosticId].status}`)
        .join('\n')
    }

    public async updateTicket(qaDiagnosticsField: string) {
      if (qaDiagnosticsField?.trim().replace(/,/g, '') !== this.currentAlerts?.trim()) {
        this.$zafClient.set(
          `ticket.customField:custom_field_${this.qaDiagnosticsFieldId}`,
          this.currentAlerts.replace(/\n/g, ',\n'),
        )
      }
    }

    private setupTicketSubmitListener() {
      this.$zafClient.on('ticket.submit.start', () => {
        this.updateTicket(this.qaDiagnosticsField)
      })
    }

    @Debounce(100)
    private async runDiagnostics() {
      const promises: Promise<any>[] = []
      for (let diagnostic of this.availableDiagnostics) {
        const data = {
          diagnosticItem: diagnostic,
          uuid: this.user?.uuid,
          serial: this.ring?.serialNumber,
        }
        const request = this.diagnosticsStore.getDiagnostics(data)
        promises.push(request)
      }
      await Promise.all(promises)
    }

    public moreInfo() {
      logEvent(this.$analytics, `${this.sidecar ? 'sidecar' : 'dashboard'}_diagnostics_v2_moreInfo_clicked`, {
        category: `${this.sidecar ? 'Sidecar' : 'Dashboard'}'`,
        action: `${this.sidecar ? 'Sidecar' : 'Dashboard'} diagnosticsV2 moreinfo clicked'`,
        label: `${this.sidecar ? 'Sidecar' : 'Dashboard'} diagnosticsV2 moreinfo clicked'`,
        page_title: `${this.sidecar ? 'Sidecar' : 'Dashboard'}`,
        page_location: window.location.toString().split('?')[0],
      })
      window.open(this.link.toLowerCase(), '_blank')
    }
  }

  export default toNative(DiagnosticsResult)
</script>

<style lang="scss" scoped>
  :deep(.v-alert) {
    .v-icon {
      margin: 0 8px !important;
    }
  }
  .v-btn--icon.v-btn--density-default {
    height: calc(var(--v-btn-height) - 7px);
  }
</style>
